import { useState } from 'react';

const useSteps = () => {
  const [activeStep, setActiveStep] = useState<number>(0);

  const steps = [
    {
      title: 'Personen',
      description: 'Personen hinzufügen',
    },
    {
      title: 'Regeln',
      description: 'Definieren wer wem wichteln darf',
    },
    { title: 'Generieren', description: 'Wichtelpaare generieren' },
  ];

  return { activeStep, setActiveStep, steps };
};

export default useSteps;
