import React, { useState } from 'react';
import styles from './AddUser.module.css';
import { Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { Player } from '../../types/player-type';
import Players from '../Players/Players';
import { v4 } from 'uuid';
const { Search } = Input;

const addIcon = <PlusOutlined />;

type Props = {
  players?: Player[];
  addPlayer: (player: Player) => void;
  removePlayer: (playerId: number | string) => void;
  addAlert: () => void;
};

const AddUsers = ({ players, addPlayer, removePlayer, addAlert }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const setSearch = (v: string) => {
    if (
      v !== '' &&
      !players?.find((p) => p.name.toLowerCase() === v.toLocaleLowerCase())
    ) {
      addPlayer({ name: v, id: v4(), pair: null });
      setSearchValue('');
    } else {
      addAlert();
    }
  };

  return (
    <div className={styles.container}>
      <Search
        placeholder="Spieler hinzufügen"
        enterButton={addIcon}
        size="large"
        value={searchValue}
        onChange={(v: any) => setSearchValue(v.target.value)}
        onPressEnter={(v: any) => setSearch(v.target.value)}
        onSearch={(v) => setSearch(v)}
      />
      <Players players={players} removePlayer={removePlayer} />
    </div>
  );
};

export default AddUsers;
