import React from 'react';
import { Player } from '../../types/player-type';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, List, Select } from 'antd';
import styles from './Players.module.css';
import cx from 'classnames';

type Props = {
  players?: Player[];
  removePlayer: (id: string | number) => void;
  showRuleSelection?: boolean;
  changeDisabledPlayers?: (player: Player, id: string[]) => void;
};
const { Option } = Select;

const Players = ({
  players,
  removePlayer,
  showRuleSelection,
  changeDisabledPlayers,
}: Props) => {
  const handleChange = (player: any, v: any) => {
    if (changeDisabledPlayers) {
      changeDisabledPlayers(player, v);
    }
  };

  return (
    <div className={styles.container}>
      <List
        bordered
        dataSource={players}
        renderItem={(player) => (
          <List.Item
            className={cx(styles.listItem, {
              [styles.fullWidth]: showRuleSelection,
            })}
          >
            <h3>{player.name}</h3>
            {!showRuleSelection && (
              <Button
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => removePlayer(player.id)}
                size="middle"
              />
            )}
            {showRuleSelection && (
              <>
                <span>
                  {player.name} darf folgenden Spielern <b>nicht</b> wichteln:
                </span>
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Spieler auswählen, denen nicht gewichtelt werden kann"
                  onChange={(v) => handleChange(player, v)}
                >
                  {players
                    ?.filter((elm) => elm.id !== player.id)
                    .map((p, i) => (
                      <Option key={i} value={p.id}>
                        {p.name}
                      </Option>
                    ))}
                </Select>
              </>
            )}
          </List.Item>
        )}
      />
    </div>
  );
};

export default Players;
