import { Alert, Button, Result } from 'antd';
import React, { useEffect, useState } from 'react';
import AddUsers from './components/AddUsers/AddUser';
import ConfigureRules from './components/ConfigureRules/ConfigureRules';
import Container from './components/Container/Container';
import GeneratedPairs from './components/GeneratedPairs/GeneratedPairs';
import ProgressSteps from './components/Steps/Steps';
import usePlayers from './hooks/usePlayers';
import useSteps from './hooks/useSteps';

const App = () => {
  const { activeStep, setActiveStep, steps } = useSteps();
  const [showIndex, setShowIndex] = useState<number>(0);
  const {
    generatePairs,
    players,
    setPlayers,
    addNewPlayer,
    removePlayer,
    changeDisabledPlayers,
    togglePairVisibility,
    decodePairs,
    setEncodedPairs,
  } = usePlayers();
  const [showError, setShowError] = useState<boolean>(false);
  const [showDuplicatePlayerAlert, setShowDuplicatePlayerAlert] =
    useState<boolean>(false);
  const [showButtons, setShowButtons] = useState<boolean>(true);

  const next = () => {
    if (players.length > 3) {
      setActiveStep(activeStep + 1);
      setShowError(false);
    } else {
      setShowError(true);
    }
  };

  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  useEffect(() => {
    const existingPairs = window.location.href.split('?pairs=')[1];
    setEncodedPairs(existingPairs);
    if (existingPairs) {
      setPlayers(decodePairs(existingPairs));
      setActiveStep(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setEncodedPairs]);

  return (
    <>
      <Container>
        {showError && (
          <Alert
            message="Mindesetens 4 Spieler nötig"
            type="error"
            closable
            onClose={() => {
              setTimeout(() => {
                setShowError(false);
              }, 500);
            }}
          />
        )}
        {showDuplicatePlayerAlert && (
          <Alert
            message="Spieler bereits vorhanden"
            type="error"
            closable
            onClose={() => {
              setTimeout(() => {
                setShowError(false);
              }, 500);
            }}
          />
        )}
        <br />
        <ProgressSteps activeStep={activeStep} steps={steps} />
        {activeStep === 0 && (
          <AddUsers
            players={players}
            addPlayer={addNewPlayer}
            removePlayer={removePlayer}
            addAlert={() => setShowDuplicatePlayerAlert(true)}
          />
        )}
        {activeStep === 1 && (
          <ConfigureRules
            players={players}
            changeDisabledPlayers={changeDisabledPlayers}
          />
        )}

        {activeStep === 2 && (
          <GeneratedPairs
            players={players}
            showIndex={showIndex}
            togglePairVisibility={togglePairVisibility}
          />
        )}

        {showButtons && (
          <div className="steps-action">
            {activeStep > 0 && (
              <Button
                style={{ margin: '0 8px 0 0' }}
                onClick={() => {
                  if (showIndex !== 0) {
                    setShowIndex(showIndex - 1);
                  } else {
                    prev();
                  }
                }}
                size="large"
              >
                Zurück
              </Button>
            )}
            {activeStep < steps.length - 2 && (
              <Button type="primary" onClick={() => next()} size="large">
                Next
              </Button>
            )}
            {activeStep === steps.length - 2 && (
              <Button
                size="large"
                type="primary"
                onClick={() => {
                  next();
                  generatePairs();
                }}
              >
                Generieren
              </Button>
            )}
            {activeStep === steps.length - 1 && (
              <Button
                type="primary"
                onClick={() => {
                  if (showIndex !== players.length - 1) {
                    togglePairVisibility();
                    setShowIndex(showIndex + 1);
                  } else {
                    setActiveStep(activeStep + 1);
                    setShowIndex(showIndex + 1);
                    setShowButtons(false);
                  }
                }}
                size="large"
              >
                {showIndex + 1 !== players.length
                  ? 'Nächsten Spieler anzeigen'
                  : 'Fertig'}
              </Button>
            )}
          </div>
        )}
        {activeStep === steps.length && (
          <Result
            status="success"
            title="Viel Spass"
            extra={[
              <Button
                type="primary"
                key="Neu starten"
                onClick={() => {
                  setActiveStep(0);
                  setPlayers([]);
                  setShowIndex(0);
                  setShowButtons(true);
                }}
              >
                Neu generieren
              </Button>,
            ]}
          />
        )}
      </Container>
    </>
  );
};

export default App;
