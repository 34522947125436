import { Button, Card } from 'antd';
import React from 'react';
import { Player } from '../../types/player-type';
import styles from './GeneratedPairs.module.css';

type Props = {
  players: Player[];
  showIndex: number;
  togglePairVisibility: (index: number) => void;
};

const GeneratedPairs = ({
  players,
  showIndex,
  togglePairVisibility,
}: Props) => {
  return (
    <div className={styles.container}>
      <Card
        title={`Spieler: ${players[showIndex].name}`}
        extra={
          <Button
            type="primary"
            onClick={() => togglePairVisibility(showIndex)}
            size="large"
          >
            {players[showIndex].showPair ? 'Verstecken' : 'Anzeigen'}
          </Button>
        }
      >
        {players[showIndex].showPair && (
          <h2>
            Du wichtels <b>{players[showIndex].pair?.name}</b>
          </h2>
        )}
      </Card>
    </div>
  );
};

export default GeneratedPairs;
