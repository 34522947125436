import React, { ReactElement } from 'react';
import styles from './Container.module.css';

type Props = {
  children?: ReactElement | ReactElement[] | any;
};

const Container = ({ children }: Props) => {
  return <div className={styles.container}>{children}</div>;
};

export default Container;
