import React from 'react';
import { Player } from '../../types/player-type';
import Players from '../Players/Players';
import styles from './ConfigureRules.module.css';

type Props = {
  players: Player[];
  changeDisabledPlayers?: (player: Player, id: string[]) => void;
};

const ConfigureRules = ({ players, changeDisabledPlayers }: Props) => {
  return (
    <div className={styles.container}>
      <Players
        players={players}
        showRuleSelection={true}
        changeDisabledPlayers={changeDisabledPlayers}
        removePlayer={() => {
          return;
        }}
      />
    </div>
  );
};

export default ConfigureRules;
