import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

type Props = {
  steps: { title: string; description: string }[];
  activeStep: number;
};

const ProgressSteps = ({ steps, activeStep }: Props) => {
  return (
    <>
      <Steps progressDot current={activeStep} responsive>
        {steps.map((step, index) => (
          <Step title={step.title} description={step.description} key={index} />
        ))}
      </Steps>
    </>
  );
};

export default ProgressSteps;
