import { useState } from 'react';
import { Player } from '../types/player-type';

const usePlayers = () => {
  const [players, setPlayers] = useState<Player[]>([]);
  const [encodedPairs, setEncodedPairs] = useState<string>('');

  const addNewPlayer = (player: Player) => {
    setPlayers([...players, player]);
  };

  const removePlayer = (playerId: string | number) => {
    const remainingPlayers = players.filter((player) => player.id !== playerId);
    setPlayers(remainingPlayers);
  };

  const changeDisabledPlayers = (p: Player, ids: string[]) => {
    const newPlayers = players.map((player) => ({
      ...player,
      disabledPairs: player.id === p.id ? ids : player.disabledPairs,
    }));
    setPlayers(newPlayers);
  };

  const generatePairs = () => {
    const localUsedUsers: Player[] = [];
    const playersWithPairs: Player[] = [];

    players.forEach((player: Player) => {
      const availablePlayers = players.filter((p) => {
        return (
          p.id !== player.id &&
          !player.disabledPairs?.includes(p.id.toString()) &&
          !localUsedUsers.map((pl: Player) => pl.id).includes(p.id)
        );
      });
      const selectedPairPlayer =
        availablePlayers[Math.floor(Math.random() * availablePlayers.length)];
      localUsedUsers.push(selectedPairPlayer);
      playersWithPairs.push({ ...player, pair: selectedPairPlayer });
    });

    setPlayers(playersWithPairs);
    setEncodedPairs(btoa(JSON.stringify(playersWithPairs)));
    window.location.href =
      window.location.href + '?pairs=' + btoa(JSON.stringify(playersWithPairs));
  };

  const togglePairVisibility = (index?: number) => {
    const newMappedPlayers = players.map((elm, i) => ({
      ...elm,
      showPair: i === index ? !elm.showPair : false,
    }));
    setPlayers(newMappedPlayers);
  };

  const decodePairs = (encodedString: string) => {
    return JSON.parse(atob(encodedString));
  };

  return {
    players,
    setPlayers,
    generatePairs,
    addNewPlayer,
    removePlayer,
    changeDisabledPlayers,
    togglePairVisibility,
    decodePairs,
    encodedPairs,
    setEncodedPairs,
  };
};

export default usePlayers;
